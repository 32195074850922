<template>
  <div :class="[$style.Indicator, { [$style._burger]: isBurger }]">
    <p :class="$style.text">
      <slot>
        {{ label }}
      </slot>
    </p>
    <div :class="$style.wrapper">
      <div
        v-for="i in 4"
        :key="i"
        :class="$style.square"
        :style="getBackgroundColor(i)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  label?: string | null;
  status?: number;
  isBurger: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: null,
  status: 0,
  isBurger: false,
});

const loadColors = ["#c0ed0b", "#edd60b", "#f0950d", "#cd2e2e"];
function getBackgroundColor(index: number) {
  if (index <= props.status) {
    return {
      backgroundColor: loadColors[props.status - 1],
    };
  }
}
</script>

<style lang="scss" module>
.Indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.text {
  padding-bottom: 0.1rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: normal;

  @include respond-to(sm) {
    color: $base-500;
  }
}

._burger .text {
  @include respond-to(sm) {
    color: var(--body-color);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.square {
  width: 1.2rem;
  height: 1.2rem;
  background: $color-square;
}
</style>
