<template>
  <transition name="fade-fast" @enter="onEnter" @after-leave="afterLeave">
    <div v-if="headerStore.isBurgerMenuVisible" :class="[$style.TheBurgerMenu]">
      <div
        ref="wrapper"
        :class="[$style.wrapper, { [$style._opened]: isContentVisible }]"
      >
        <div :class="$style.content">
          <div :class="$style.header">
            <button type="button">
              <SvgoIconClose
                :class="$style.close"
                @click="afterLeave"
              ></SvgoIconClose>
            </button>
          </div>
          <div :class="$style.column">
            <CommonToggleColorMode :class="$style.toggle" />
            <CommonMainMenu
              :menu-list="headerStore.menuList"
              is-burger
              :class="$style.menu"
            />
            <UiIndicatorVIndicator
              v-if="congestion"
              :class="$style.indicator"
              :status="congestion.load"
              is-burger
              >Загруженность сети btc</UiIndicatorVIndicator
            >
          </div>
          <NuxtLink v-if="!isLogged" to="/login" :class="$style.login">
            <div class="h3">Вход в личный кабинет</div>
            <SvgoIconLogin :class="$style.icon"></SvgoIconLogin>
          </NuxtLink>
          <NuxtLink v-else to="/account/dashboard" :class="$style.login">
            <div class="h3">{{ email }}</div>
            <SvgoIconLogin :class="$style.icon"></SvgoIconLogin>
          </NuxtLink>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useHeaderStore } from "~/stores/headerStore";
import { useCalculator } from "~/composables/useCalculator";

const { congestion } = useCalculator();

const isContentVisible = ref(false);

const headerStore = useHeaderStore();

const wrapper = ref<HTMLElement | null>(null);

const { isLogged, email } = useUser();

function afterLeave() {
  isContentVisible.value = false;
  setTimeout(() => {
    headerStore.toggleBurgerMenu(false);
  }, 200);
  unlockBody();
}

onClickOutside(wrapper, () => {
  afterLeave();
});

function onEnter() {
  isContentVisible.value = true;
  lockBody();
}

const route = useRoute();

watch(
  () => route.name,
  () => {
    afterLeave();
  },
);
</script>

<style lang="scss" module>
.TheBurgerMenu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background: hsl(0deg 0% 0% / 70%);
  backdrop-filter: blur(6px);
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 100%;
  padding: 16px 16px 16px 60px;
  background-color: var(--bg-color-main);
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s;

  @include respond-to(xs) {
    padding: 16px 16px 60px;
    width: 100%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 60px;
  flex: 1 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

._opened {
  transform: translateX(0);
}

.close {
  width: 24px;
  color: #c0ed0b;
}

.header {
  text-align: right;
  margin-bottom: 20px;
}

.login {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #c0ed0b;

  @include respond-to(xs) {
    font-weight: 400;
  }
}

.icon {
  width: 27px;

  @include respond-to(xs) {
    width: 18px;
  }
}
</style>
