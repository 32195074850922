<template>
  <label :class="$style.TheToggle">
    <client-only>
      <input
        v-model="isDark"
        :class="$style.checkbox"
        type="checkbox"
        @change="
          isDark
            ? ($colorMode.preference = 'dark')
            : ($colorMode.preference = 'light')
        "
      />
      <span :class="$style.slider">
        <span :class="$style.circle">
          <client-only>
            <SvgoIconMoon :class="$style.icon" />
            <template #fallback>
              <SvgoIconMoon :class="$style.icon" />
            </template>
          </client-only>
        </span>
      </span>
    </client-only>
  </label>
</template>

<script setup lang="ts">
const colorMode = useColorMode();
const isDark = computed({
  get() {
    return colorMode.value === "dark";
  },
  set() {
    colorMode.preference = colorMode.value === "dark" ? "light" : "dark";
  },
});
</script>

<style lang="scss" module>
.TheToggle {
  position: relative;
  display: block;
  width: 5.4rem;
  height: 2.5rem;
  color: $body-color;
}

.checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.slider {
  position: absolute;
  inset: 0;
  background-color: #929292;
  border-radius: 5rem;
  cursor: pointer;
  transition: background-color $default-transition;
}

.circle {
  position: absolute;
  content: "";
  top: 50%;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #121212;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  background-color: #fff;
  transform: translateX(0) translateY(-50%);
  transition: all $default-transition;
}

.checkbox:checked + .slider .circle {
  transform: translateX(2.9rem) translateY(-50%);
}

.checkbox:checked + .slider {
  background-color: $brand-green;
  transition: background-color $default-transition;
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
}
</style>
